import TextField from "@mui/material/TextField";
import { CSSProperties, useContext, useEffect } from "react";
import { useImmer } from "use-immer";

import { ToastContext } from "../../providers/ToastProvider";
import { SearchContext } from "../../providers/SearchProvider";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const SearchBar = ({
  id,
  inputProps,
  style = {},
}: {
  id?: string;
  style?: CSSProperties;
  inputProps?: any;
}) => {
  const { setError } = useContext(ToastContext);
  const { inProgress, keywords, onClear, onClose, setKeywords } = useContext(SearchContext);

  const [state, setState] = useImmer<{
    keywords: string;
  }>({
    keywords: keywords,
  });

  const endAdornment = inProgress ? (
    <InputAdornment position="end">
      <CircularProgress
        color="inherit"
        size={16}
      />
    </InputAdornment>
  ) : state.keywords.length > 0 ? (
    <InputAdornment position="end">
      <IconButton
        onClick={() => {
          onClear();
          onClose();
          setState((state) => {
            state.keywords = "";
          });
        }}
      >
        <CloseOutlinedIcon />
      </IconButton>
    </InputAdornment>
  ) : null;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const trimmed = state.keywords.replace(/\W/g, "").trim();
        if (state.keywords && trimmed.length > 2) {
          setKeywords(state.keywords.trim());
        } else {
          setError("Please enter more than 2 letters to run a search.");
        }
      }}
      style={style}
    >
      <TextField
        id={id}
        className="text"
        InputProps={{
          ...inputProps,
          onInput: (e) => {
            const value = (e.target as HTMLInputElement).value;
            setState((state) => {
              state.keywords = value;
            });
          },
          startAdornment: (
            <SearchIcon
              sx={{
                marginRight: "0.5rem",
              }}
            />
          ),
          endAdornment: endAdornment,
        }}
        value={state.keywords}
        placeholder="Search for company by name or domain"
        variant="outlined"
        size="small"
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              border: "solid 0.2rem grey",
            },
          },
        }}
      />
    </form>
  );
};

export default SearchBar;
