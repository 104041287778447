import axios from "axios";
import { defaultEmptyFilters } from "../utils/filters";
import { ModalFilters } from "../components/modals/AdvancedFiltersModal";
import {
  FlattenedIntent,
  SignalSummaryFilters,
  SignalFilters,
} from "../types/intent";
import { areFiltersModified } from "../utils/filters";
import { camelize, download, formatDateMonth } from "../utils";
import { IntentPagination, Screener } from "../types/search";

export const getScreenerAvailableFilters = async (screenerId: string) => {
  return axios({
    method: "GET",
    url: `/api/v1/screeners/${screenerId}/filters`,
    withCredentials: true,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return {
        industries: [],
        states: [],
      };
    });
};

export const updateScreener = async (screenerId: string, data: object) => {
  return axios({
    method: "PUT",
    url: `/api/v1/screeners/${screenerId}`,
    withCredentials: true,
    data: data,
  });
};

export const downloadFiltered = async (
  screenerId: string,
  filters: SignalFilters,
  filterKeyword: string
) => {
  return axios({
    method: "POST",
    url: screenerId
      ? `/api/v1/screeners/${screenerId}/download`
      : "/api/v1/intent/download",
    withCredentials: true,
    responseType: "blob",
    data: {
      keywords: filterKeyword,
      signal_type: filters.signalType,
      list_id: filters.list.id,
      screener_id: filters.screener.id,
      date_range:
        filters.dateRange.start && filters.dateRange.end
          ? `${formatDateMonth(
              filters.dateRange.start.toString()
            )}:${formatDateMonth(filters.dateRange.end.toString())}`
          : "",
    },
  })
    .then((response) => {
      download(response);
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const downloadAll = async (screenerId: string) => {
  return axios({
    method: "POST",
    url: screenerId
      ? `/api/v1/screeners/${screenerId}/download`
      : "/api/v1/intent/download",
    withCredentials: true,
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
  })
    .then((response) => {
      download(response);
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const downloadSummary = async (
  screenerId: string,
  summaryFilters: SignalSummaryFilters,
  modalFilters: ModalFilters
) => {
  return axios({
    method: "POST",
    url: `/api/v1/screeners/${screenerId}/download/summary`,
    withCredentials: true,
    responseType: "blob",
    data: {
      signal_type: summaryFilters.signalType,
      list_id: summaryFilters.list.id,
      screener_id: summaryFilters.screener.id,
      date_range: summaryFilters.dateRange,
      advanced_filters: modalFilters,
    },
  })
    .then((response) => {
      download(response);
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getEntries = async (
  screener: Screener,
  modalFilters: ModalFilters,
  signalFilters: SignalFilters,
  page?: number,
  perPage?: number
) => {
  const defaultSearchFilters: ModalFilters = {
    geolocation:
      screener?.iteration?.companySpecs?.geolocation ||
      defaultEmptyFilters.geolocation,
    industry:
      screener?.iteration?.companySpecs?.industry ||
      defaultEmptyFilters.industry,
    employeeRange:
      screener?.iteration?.companySpecs?.employeeRange ||
      defaultEmptyFilters.employeeRange,
    minScore: screener?.iteration?.minScore || defaultEmptyFilters.minScore,
  };

  const payload: any = {
    date_range:
      signalFilters.dateRange.start && signalFilters.dateRange.end
        ? `${formatDateMonth(
            signalFilters.dateRange.start.toString()
          )}:${formatDateMonth(signalFilters.dateRange.end.toString())}`
        : "",
    signal_type: signalFilters.signalType,
    list_id: signalFilters.list.id,
    screener_id: signalFilters.screener.id,
  };

  // Prevent redundant queries; can be moved to the BE
  if (modalFilters.geolocation !== defaultSearchFilters.geolocation) {
    payload.geolocation = modalFilters.geolocation;
  }
  if (modalFilters.industry !== defaultSearchFilters.industry) {
    payload.industry = modalFilters.industry;
  }
  if (modalFilters.employeeRange !== defaultSearchFilters.employeeRange) {
    payload.employee_range = modalFilters.employeeRange;
  }
  if (modalFilters.minScore !== defaultSearchFilters.minScore) {
    payload.min_score = modalFilters.minScore;
  }

  return await axios({
    method: "POST",
    url: `/api/v1/screeners/${screener.id}/iterations/entries`,
    data: {
      filters: payload,
      pagination: {
        page,
        per_page: perPage,
      },
    },
    withCredentials: true,
  })
    .then((response) => {
      const parsedResponse = camelize<{
        entries: FlattenedIntent[];
        metadata: IntentPagination;
      }>(response.data);
      return parsedResponse;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getEntriesSummary = async (
  screenerId: string,
  signalFilters: SignalSummaryFilters,
  modalFilters: ModalFilters,
  page: number,
  perPage: number
) => {
  const params = new URLSearchParams();

  params.append("date_range", signalFilters.dateRange);
  if (signalFilters.signalType) {
    params.append("signal_type", signalFilters.signalType);
  }
  if (signalFilters.list.id) {
    params.append("list_id", signalFilters.list.id);
  }
  if (signalFilters.screener.id) {
    params.append("screener_id", signalFilters.screener.id);
  }
  params.append("page", page.toString());
  params.append("per_page", perPage.toString());

  if (areFiltersModified(modalFilters, defaultEmptyFilters)) {
    params.append("advanced_filters", JSON.stringify(modalFilters));
  }

  const response = await axios({
    method: "GET",
    url: `/api/v1/screeners/${screenerId}/iterations/entries/summary?${params.toString()}`,
    withCredentials: true,
  });

  return response.data.entries;
};
