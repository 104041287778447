import { EmployeeRanges } from "../data/employeeranges";
import { ModalFilters } from "../components/modals/AdvancedFiltersModal";
import { SignalSummaryFilters } from "../types/intent";
import { Metadata, Screener } from "../types/search";
import dayjs from "dayjs";
import { displayDate } from ".";

export const parseDate = (date: string) => {
  if (date.includes("-")) {
    date = date.replace("-", "");
  }
  const year = parseInt(date.slice(0, 4));
  const month = parseInt(date.slice(4, 6));
  return dayjs()
    .set("year", year)
    .set("month", month - 1)
    .set("date", 1);
};

const sortObjectAndArrays = (obj: any): any => {
  if (Array.isArray(obj)) {
    return [...obj].sort();
  } else if (obj && typeof obj === "object") {
    const sorted: any = {};
    Object.keys(obj)
      .sort()
      .forEach((key) => {
        sorted[key] = sortObjectAndArrays(obj[key]);
      });
    return sorted;
  }
  return obj;
};

export const getDefaultSignalFilters = (
  metadata: Metadata,
  startDateRange: string | null,
  endDateRange: string | null
) => {
  return {
    dateRange: {
      key:
        startDateRange !== null && endDateRange !== null
          ? `${displayDate(parseDate(startDateRange))} to ${displayDate(
              parseDate(endDateRange)
            )}`
          : "Last 3 Months",
      start: startDateRange
        ? parseDate(startDateRange)
        : parseDate(metadata.last3Months.start),
      end: endDateRange
        ? parseDate(endDateRange)
        : parseDate(metadata.last3Months.end),
      apply: startDateRange !== null && endDateRange !== null,
    },
    signalType: [],
    list: {
      id: "",
      name: "",
    },
    screener: {
      id: "",
      name: "",
    },
  };
};

export const defaultSignalSummaryFilters: SignalSummaryFilters = {
  dateRange: "last_3_mo",
  signalType: null,
  list: { id: null, name: null },
  screener: { id: null, name: null },
};

export const defaultEmptyFilters: ModalFilters = {
  geolocation: {
    state: {
      value: [],
      type: "include",
    },
    city: {
      value: [],
      type: "include",
    },
    country: {
      value: [],
      type: "include",
    },
  },
  industry: {
    sic2: {
      value: [],
      type: "include",
      includeSimilar: false,
    },
  },
  employeeRange: {
    min: 0,
    max: EmployeeRanges.length - 1,
    source: "range",
  },
  minScore: 0,
};

export const areFiltersModified = (
  filters: ModalFilters,
  baseFilters: ModalFilters
) => {
  return (
    JSON.stringify(filters.industry?.sic2.value) !==
      JSON.stringify(baseFilters?.industry?.sic2.value) ||
    filters.industry?.sic2.type !== baseFilters?.industry?.sic2.type ||
    JSON.stringify(filters.geolocation?.state.value) !==
      JSON.stringify(baseFilters?.geolocation?.state.value) ||
    filters.geolocation?.state.type !== baseFilters?.geolocation?.state.type ||
    filters.employeeRange?.min !== baseFilters?.employeeRange?.min ||
    filters.employeeRange?.max !== baseFilters?.employeeRange?.max ||
    (filters.minScore !== baseFilters?.minScore &&
      !(
        Math.min(filters.minScore, baseFilters?.minScore) === 0 &&
        Math.max(filters.minScore, baseFilters?.minScore) === 1
      ))
  );
};
