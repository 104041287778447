import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { ReactNode, createContext, useContext, useEffect } from "react";
import * as React from "react";
import { useImmer } from "use-immer";

import AddToScreenerButton from "../components/AddToScreenerButton";
import { AddToScreenerModalContext } from "../components/modals/AddToScreenerModal";
import ModalContainer from "../components/modals/base/ModalContainer";
import ModalPage from "../components/modals/base/ModalPage";
import SearchBar from "../components/SearchBar";
import { TableCell, TableHead } from "../components/tables/common";
import CompanyRow from "../components/tables/CompanyRow";
import { Companies } from "../types/companies";
import { camelize } from "../utils";

import { useMediaQueryContext } from "./MediaQueryProvider";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

const PerPage = 10;

export type SearchParameters = {
  keywords: string;
};

export type SearchContextValue = {
  open: boolean;
  keywords: string;
  setKeywords: (_k: string) => void;
  inProgress: boolean;
  onOpen: (sp?: SearchParameters) => void;
  onSearch: (sp?: SearchParameters) => Promise<void>;
  onClose: () => void;
  onClear: () => void;
  onFocus: () => void;
  setOnFocus: (onFocus: () => void) => void;
  setOnIntentUpdate: (onIntentUpdate: () => Promise<void>) => void;
  setOnClose: (onClose: () => void) => void;
};

export const SearchContext = createContext<SearchContextValue>({
  open: false,
  inProgress: false,
  keywords: "",
  setKeywords: (_k: string) => undefined,
  onOpen: (_sp?: SearchParameters) => undefined,
  onSearch: async (_sp?: SearchParameters) => undefined,
  onClose: () => undefined,
  onClear: () => undefined,
  onFocus: () => undefined,
  setOnFocus: (_onFocus: () => void) => undefined,
  setOnIntentUpdate: (_onIntentUpdate: () => Promise<void>) => undefined,
  setOnClose: (_onClose: () => void) => undefined,
});

type SearchResultEntry = {
  company: Companies;
  hasIntent: boolean;
};

const SearchProvider = ({ children }: { children: ReactNode }) => {
  const addToScreenerModal = useContext(AddToScreenerModalContext);
  const { isLowRes, isMediumRes } = useMediaQueryContext();

  const clearedState = {
    keywords: "",
    results: [],
    totalResults: 0,
    hasMore: false,
    page: 1,
    firstSearch: true,
    bulkSelected: new Set<string>(),
  };

  const defaultState = {
    open: false,
    inProgress: false,
    onClose: () => undefined,
    onFocus: () => undefined,
    ...clearedState,
  };

  const [state, setState] = useImmer<{
    open: boolean;
    keywords: string;
    results: SearchResultEntry[];
    hasMore: boolean;
    inProgress: boolean;
    firstSearch: boolean;
    bulkSelected: Set<string>;
    page: number;
    onIntentUpdate?: () => Promise<void>;
    onClose: () => void;
    onFocus: () => void;
  }>(defaultState);

  const ResultItem = ({
    entry,
    selected,
    onSelect,
  }: {
    entry: SearchResultEntry;
    selected: boolean;
    onSelect: (key: string) => void;
  }) => {
    return (
      <CompanyRow
        key={"entry"}
        companyId={entry.company.id}
      >
        <TableCell
          padding={"checkbox"}
          sx={{
            padding: "0 16px 0 16px",
          }}
        >
          <Checkbox
            size={isMediumRes || isLowRes ? "small" : undefined}
            checked={selected}
            onChange={() => onSelect(entry.company.domainName)}
          />
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {entry.company.name}
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <a
            href={`https://${entry.company.domainName}`}
            target="_blank"
            rel="noreferrer"
          >
            {entry.company.domainName}
          </a>
        </TableCell>
        <TableCell sx={{
          width: "auto",
        }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {
              entry.hasIntent ? (
                <Tooltip
                  title="Intent available"
                  arrow
                >
                  <BookmarkIcon color="success" />
                </Tooltip>
              ) : (
                <Tooltip
                  title="No intent available"
                  arrow
                >
                  <BookmarkBorderIcon color="disabled" />
                </Tooltip>
              )
            }
            <AddToScreenerButton
              onClick={() =>
                addToScreenerModal.show([entry.company.domainName])
              }
            />
          </Box>
        </TableCell>
      </CompanyRow>
    );
  };

  const fetchSearchResults = async (showMore = false) => {
    let start = 0;
    let end = PerPage;

    if (showMore) {
      start = state.page * PerPage;
      end = (state.page + 1) * PerPage;

      setState((state) => {
        state.page += 1;
      });
    }

    await axios({
      method: "GET",
      url: "/api/v1/companies/search",
      params: {
        keywords: state.keywords,
        start: start,
        end: end,
      },
      withCredentials: true,
    })
      .then((response) => {
        setState((state) => {
          const camelizedPayload = camelize<{
            results: SearchResultEntry[];
            metadata: {
              hasMore: boolean;
            };
          }>(response.data);
          const results = camelizedPayload["results"] as SearchResultEntry[];
          if (showMore) {
            state.results = [...state.results, ...results];
          } else {
            state.results = results;
          }
          state.hasMore = camelizedPayload["metadata"]["hasMore"] as boolean;
          state.firstSearch = false;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const runSearch = async (showMore = false) => {
    if (state.keywords && !state.inProgress) {
      setState((state) => {
        state.inProgress = true;
      });

      return await fetchSearchResults(showMore).then(() => {
        setState((state) => {
          state.inProgress = false;
        });
      });
    }
  };

  useEffect(() => {
    if (state.keywords !== "") {
      // for onSearch uses
      setState((state) => {
        state.open = true;
      });
      runSearch();
    }
  }, [state.keywords]);

  const context = {
    open: state.open,
    keywords: state.keywords,
    setKeywords: (keywords: string) => {
      setState((state) => {
        state.keywords = keywords;
      });
    },
    inProgress: state.inProgress,
    onOpen: (sp?: SearchParameters) => {
      setState((state) => {
        state.open = true;
        state.keywords = sp?.keywords || "";
      });
    },
    onClose: () => {
      setState(defaultState);
    },
    onClear: () => {
      setState({
        ...clearedState,
        open: state.open,
        inProgress: state.inProgress,
        bulkSelected: new Set<string>(),
        onClose: state.onClose,
        onFocus: state.onFocus,
      });
    },
    setOnIntentUpdate: (onIntentUpdate: () => Promise<void>) => {
      setState((state) => {
        state.onIntentUpdate = onIntentUpdate;
      });
    },
    setOnClose: (onClose: () => void) => {
      setState((state) => {
        state.onClose = onClose;
      });
    },
    onSearch: async (searchParameters?: SearchParameters) => {
      setState((state) => {
        if (searchParameters?.keywords) {
          state.keywords = searchParameters?.keywords;
        } else {
          state.keywords = "";
          state.open = true;
        }
      });
    },
    setOnFocus: (onFocus: () => void) => {
      setState((state) => {
        state.onFocus = onFocus;
      });
    },
    onFocus: () => {
      state.onFocus();
    },
  };

  const noneSelected = state.bulkSelected.size === 0;
  const someSelected = state.bulkSelected.size > 0;
  const allSelected =
    state.bulkSelected.size === state.results.length &&
    state.results.length > 0;

  const someUntracked =
    state.results
      .filter((se) => state.bulkSelected.has(se.company.domainName)).length > 0;

  const onSelect = (key: string) => {
    setState((state) => {
      if (state.bulkSelected.has(key)) {
        state.bulkSelected.delete(key);
      } else {
        state.bulkSelected.add(key);
      }
    });
  };

  const onSelectAll = () => {
    setState((state) => {
      state.bulkSelected = new Set<string>(
        state.results.map((e) => e.company.domainName)
      );
    });
  };

  const onUnselectAll = () => {
    setState((state) => {
      state.bulkSelected = new Set<string>();
    });
  };

  const hasSearchRun = !state.firstSearch;

  const footer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {state.hasMore ? (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => runSearch(true)}
        >
          Show More
        </Button>
      ) : (
        <Box></Box>
      )}
    </Box>
  );

  const checkbox = (
    <Box
      style={{
        padding: "0 0 1rem 16px",
        height: "100%",
      }}
      display={"flex"}
      flexDirection={"row"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        height={"28px"}
      >
        {
          someSelected ? (
            <Tooltip
              title="Add all to screener"
              arrow
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  addToScreenerModal.show(Array.from(state.bulkSelected));
                }}
                sx={{
                  padding: "2px",
                }}
              >
                <CreateNewFolderIcon color={someUntracked ? "success" : "disabled"} />
              </IconButton>
            </Tooltip>
          ) : null
        }
      </Box>
    </Box>
  );

  const resultsModalBody =
    state.results.length > 0 ? (
      <TableContainer
        sx={{
          overflowX: "clip",
          overflowY: "scroll",
          marginTop: "-0.1rem",
        }}
      >
        <Table
          stickyHeader
          size="small"
          sx={{ tableLayout: "fixed" }}
        >
          <TableHead
            sx={{
              paddingTop: "5rem",
            }}
          >
            <TableRow>
              <TableCell
                padding={"checkbox"}
                sx={{
                  // Overwite the `th` element style to match the rest of this component
                  padding: "0 20px !important",
                }}
              >
                <Tooltip
                  title={`Select Displayed (${state.results.length})`}
                  arrow
                >
                  <Checkbox
                    size={isMediumRes || isLowRes ? "small" : undefined}
                    checked={allSelected}
                    indeterminate={someSelected && !allSelected}
                    onChange={() => {
                      if (someSelected) {
                        onUnselectAll();
                      } else if (noneSelected) {
                        onSelectAll();
                      }
                    }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell>
                Company Name
              </TableCell>
              <TableCell>
                Domain Name
              </TableCell>
              <TableCell sx={{
                width: "20%",
                textWrap: "wrap",
                textAlign: "center",
              }}>
                Quick Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.results.map((entry) => (
              <ResultItem
                key={`ri-${entry.company.id}`}
                entry={entry}
                selected={state.bulkSelected.has(entry.company.domainName)}
                onSelect={onSelect}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        No matches found. Please, try another search.
      </Box>
    );

  const modalBody = state.firstSearch ? <></> : resultsModalBody;

  return (
    <SearchContext.Provider value={context}>
      {children}
      <ModalContainer
        open={context.open}
        onClose={() => {
          state.onClose();
          context.onClose();
        }}
        sx={{
          height: state.firstSearch ? "inherit" : "75%",
          width: isMediumRes ? "60%" : "80%",
          display: "flex",
        }}
      >
        <ModalPage
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          title={"Search"}
          body={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                paddingTop: "1rem",
              }}
            >
              <Box
                id="header"
                display="flex"
                flexDirection="column"
                sx={{
                  gap: "1rem",
                }}
              >
                <SearchBar />
                <Box>
                  {state.firstSearch || state.results.length === 0
                    ? null
                    : checkbox}
                </Box>
              </Box>
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                }}
              >
                {modalBody}
              </Box>
              {hasSearchRun ? (
                <Box
                  sx={{
                    padding: "1rem 0",
                  }}
                >
                  {footer}
                </Box>
              ) : null}
            </Box>
          }
        />
      </ModalContainer>
    </SearchContext.Provider>
  );
};

export default SearchProvider;
