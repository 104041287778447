import { HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import honeybadger from "./config/hb";

const ErrorFallback = () => {
  useEffect(() => {
    window.location.assign("/error");
  }, []);
  return <div>Something went wrong. Redirecting...</div>;
};


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <HoneybadgerErrorBoundary
    honeybadger={honeybadger}
    ErrorComponent={ErrorFallback}
  >
    <App />
  </HoneybadgerErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
