import axios from "axios";
import honeybadger from "../config/hb";
import { ReactNode, createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";

import { User } from "../types/search";
import { camelize } from "../utils";

import { PageHeaderContext } from "./PageHeaderProvider";


export type Session = {
  user?: User;
};

export type SessionContextValue = Session & {
  exists: boolean;
  login: (user: User) => void;
  refresh: () => Promise<void>;
  logout: () => void;
  setShownWelcomeAt: () => void;
};

export const SessionContext = createContext<SessionContextValue>({
  exists: false,
  login: async () => undefined,
  logout: async () => undefined,
  refresh: async () => undefined,
  setShownWelcomeAt: () => undefined,
  user: undefined,
});

const SESSION_KEY = "fintent-session";

const SessionProvider = ({ children }: { children: ReactNode }) => {
  const pageHeader = useContext(PageHeaderContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Add Axios interceptor
    const interceptor = axios.interceptors.response.use(
      (response) => response, // Pass through successful responses
      (error) => {
        if (error.response?.status === 401) {
          // Clear session and redirect to login
          localStorage.removeItem(SESSION_KEY);
          setState((state) => {
            state.exists = false;
          });
          navigate("/login", { replace: true });
        }
        return Promise.reject(error); // Let other errors propagate
      }
    );

    // Cleanup interceptor on unmount
    return () => axios.interceptors.response.eject(interceptor);
  }, [navigate]);

  const getSession = () => {
    try {
      const session_value = localStorage.getItem(SESSION_KEY);
      if (session_value) {
        return JSON.parse(session_value) as Session;
      } else {
        return undefined;
      }
    } catch {
      console.error("unable to parse session from local storage");
      return undefined;
    }
  };

  const [state, setState] = useImmer<{
    exists: boolean;
    session?: Session;
  }>({
    exists: getSession() != null,
    session: getSession(),
  });

  const login = (user: User) => {
    const session = {
      user: user,
      version: 1,
    };
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
    honeybadger.setContext({
      user_id: user.id,
      email: user.email,
    });
    setState({
      exists: true,
      session,
    });
  };

  const setShownWelcomeAt = () => {
    setState((state) => {
      if (state.session && state.session.user) {
        state.session.user.shownWelcomeAt = new Date().toString();
        localStorage.setItem(SESSION_KEY, JSON.stringify(state.session));
      }
    });
  };

  const logout = () => {
    pageHeader.setPageHeader("", "", undefined);
    localStorage.removeItem(SESSION_KEY);
    honeybadger.setContext({});
    setState((state) => {
      state.exists = false;
    });
  };

  const refresh = async (): Promise<void> => {
    try {
      const response = await axios.get("/api/v1/session/refresh");
      login(camelize(response.data) as User);
    } catch (error) {
      console.error("Session refresh failed", error);
      logout(); // Log out and clear session
      window.location.href = "/login"; // Redirect to login
    }
  };

  useEffect(() => {
    window.addEventListener("storage", (e: StorageEvent) => {
      if (e.key === SESSION_KEY) {
        const session = getSession();
        setState({
          exists: session != null,
          session,
        });
      }
    });
  });

  useEffect(() => {
    if (state.exists) {
      refresh();
    }
  }, [state.exists]);

  const context = {
    exists: state.exists,
    login,
    logout,
    refresh,
    setShownWelcomeAt,
    ...state.session,
  };

  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
