import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { useState } from "react";

interface AddToScreenerButtonProps {
  onClick: () => Promise<void>;
  text?: string;
}

const AddToScreenerButton: React.FC<AddToScreenerButtonProps> = ({ onClick, text = "Add to Screener" }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Tooltip title={text} arrow>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setLoading(true);
          onClick().finally(() => {
            setLoading(false);
          });
        }}
        disabled={loading}
      >
        {
          loading ? (
            <CircularProgress color="inherit" size={16} />
          ) : (
            <CreateNewFolderIcon color="success" />
          )
        }

      </IconButton>
    </Tooltip>
  );
};

export default AddToScreenerButton;
